import { type ShippingOption, type ShippingOptions } from '../types';

export const getShippingOptionsByProfile = (shippingOptions: ShippingOptions) => {
  const shippingOptionsByProfile = shippingOptions.reduce<Record<string, ShippingOption[]>>((acc, shippingOption) => {
    const profileId = shippingOption.profile_id;

    if (!profileId) return acc;

    if (!acc[profileId]) acc[profileId] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
    acc[profileId].push(shippingOption as any);

    return acc;
  }, {});

  Object.keys(shippingOptionsByProfile).forEach((profileId) =>
    shippingOptionsByProfile[profileId].sort((a, b) => (a.amount || 0) - (b.amount || 0)),
  );

  return shippingOptionsByProfile;
};
