import type { Cart as MedusaCart } from '@medusajs/medusa';
import { useFetchers } from '@remix-run/react';
import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';
import { useStorefront } from './useStorefront';

interface Cart extends MedusaCart {
  emailExists?: boolean;
  open?: boolean;
}

export const useCart = () => {
  const { state, actions } = useStorefront();
  const data = useRootLoaderData();
  const fetchers = useFetchers();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const cartFetchers = fetchers.filter((f) => f.data?.cart);
  const removingLineItemFetchers = fetchers.filter(
    (f) => f.formData?.get('subaction') === 'deleteItem' && f.formData?.get('lineItemId'),
  );
  const cart = data?.cart as Cart | undefined;

  let isAddingItem = false;
  let isRemovingItemId;
  const cartFetcher = cartFetchers[cartFetchers.length - 1];
  const removingLineItemFetcher = removingLineItemFetchers[removingLineItemFetchers.length - 1];

  if (cartFetcher && cartFetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(cartFetcher.state))
    isAddingItem = true;

  if (removingLineItemFetcher && ['loading', 'submitting'].includes(removingLineItemFetcher.state))
    isRemovingItemId = removingLineItemFetcher.formData?.get('lineItemId');

  return {
    cart,
    isAddingItem,
    isRemovingItemId,
    cartDrawerOpen: state.cart.open,
    toggleCartDrawer: actions.toggleCartDrawer,
  };
};
